
.act-main {
   padding: 10px;
   &, & * {
    box-sizing: border-box;
  }
  .form-box {
      width: 500px;
  }
}
